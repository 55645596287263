<template>
  <div class="container mb-60 baojia-box liucheng-top rounded-lg">
    <v-row>
      <v-col  xl="8" lg="8" md="12" sm="12" cols="12">
        <v-card flat class="pa-4">
            
            <div class="text-body-1 font-weight-bold">
                <a  @click="backFn"><span>{{$t('返回')}} </span></a>
                
                <v-icon>mdi-chevron-double-left</v-icon>
                <span> 攻略详情</span>
            </div>
          <div class="my-0">
              <div class="text-center  text-body-1 font-weight-bold">{{info.title}}</div> 
              <div class="grey-text text-right">{{info.create_time | formFilter}}</div> 
              <div v-if="info.details" v-html="replaceWhite(info.details)" ref="video"></div>
            </div>
        </v-card>
      </v-col>
      <v-col  xl="4" lg="4" md="12" sm="12" cols="12">
        <v-card flat class="pa-4">
            <div class="text-body-1 font-weight-bold">{{$t('文章搜索')}}</div>
            <v-card flat color="#F2F2F2" class="d-flex mt-2">
                <input type="text" class="pa-2" style="width:100%;outline: none;" v-model="keyword" :placeholder="$t('输入关键字进行搜索')">
                <v-btn
                    class=""
                    height="36"
                    width="60"
                    depressed
                    color="primary"
                    @click="toserach"
                  >
                    <div>
                <v-img
                  width="24"
                  height="24"
                  class=""
                  src="@/assets/images/icon/icon_search.svg"
                  alt=""
                ></v-img>

              </div>
                  </v-btn>
            </v-card>
        </v-card>
        <v-card flat class="pa-4">
            <div class="d-flex justify-space-between align-center">
                <div class="text-body-1 font-weight-bold">{{$t('最新发布')}}</div>
                <div>
                    <v-btn text width="20"  min-width="20">

                    <v-icon  @click="lefttoggle('left')">mdi-chevron-left</v-icon>
                    </v-btn>
                    {{this.pagearr.page}}/{{this.pagearr.pagetotal}}
                    <v-btn text width="20" min-width="20">

                    <v-icon  @click="lefttoggle('right')">mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="d-flex align-center cursor-pointer  mt-4" v-for="(item ,index) in Articledata" :key="index" @click="goDetail(item)">
              <div>
                <v-img class="mr-2 rounded"  width="80" height="80" :src="item.face"></v-img>

              </div>
                <div>
                    <div class="mb-2 line2">{{item.title}}</div>
                    <div class="grey--text">{{item.create_time | formFilter}}</div>
                </div> 
            </div>
            <empty :describe="$t('暂无搜索内容~')" :imgSrc="emptysrc" v-if="Articledata.length==0"></empty>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import empty from "@/components/base/empty";
import ArticleCart from "@/components/base/articleCard";
export default {
  components: {
    ArticleCart,
    empty
  },
  data() {
    return {
        remaimodel:"",
        tools:"",
        keyword:'',
        param:{},
        id:0,
      xianshitemai: [],
      Articledata:[],
      info:{},
      pagearr: {
        pageList: [15, 30, 50, 100, 500, 1000, 2000],
        page: 1,
        pagenum: 3,
        pagetotal: 50,
        total: 0,
      },
      emptysrc:require('@/assets/images/other/wusousuo@2x.png')
    };
  },
  mounted() {
    this.id=this.$route.query.id
    this.param["keyword"] = this.keyword;
    this.param["page"] = this.pagearr.page;
    this.param["page_size"] = this.pagearr.pagenum;
    this.getdata()
    this.getnewArticle()
  },
  methods: {
    //获取返利攻略
    getdata(){
      this.$api.home.StrategyDetail({id:this.id}).then((res)=>{
        this.info=res.data
        if(this.info.details){

        }
        this.info.details=this.info.details.replace(/<video/g, "<video style='width:100%;height:auto;'")
        this.info.details=this.info.details.replace(/<iframe/g, "<iframe @load='loadFrame' id='videos' style='width:50%;height:auto;display:block;margin:0 auto'")

        
      })
    }, 
    loadFrame(){
      const iframeBox = document.getElementById('videos')
      // 获取iframe html文件
      const doc = iframeBox.contentWindow.document
      // 获取iframe html文件head
      const head = doc.getElementsByTagName('head')
      // 新建link标签
      const linkTag = document.createElement('style')
      // 设置link标签id
      // linkTag.id = 'newstyle'
      // // link标签引入css文件的地址 ；window.STATIC_URL 是全局变量，指向静态资源目录，需要自己指定
      // linkTag.href = `${window.STATIC_URL}css/${this.link}.css`
      // // 设置link标签属性
      // linkTag.setAttribute('rel', 'stylesheet')
      // // 设置link标签属性
      // linkTag.setAttribute('type', 'text/css')
      // // 将link标签添加进iframe html文件的head里
      // head[0].append(linkTag)
    },
    // 获取最新文章
    getnewArticle(){
      this.$api.home.latestRelease(this.param).then((res)=>{
        if(res.status=='success'){
          this.Articledata=res.data
          this.pagearr.pagetotal = Math.ceil(
            parseInt(res.total) / this.pagearr.pagenum
          );
        }else {
          this.Articledata=[]
          this.pagearr.pagetotal = 0;
        }
      })
    },
    lefttoggle(val){
      if(val=='left' && this.pagearr.page>1){
        this.pagearr.page--
        this.param['page']--
      }
      if(val=='right' && this.pagearr.page<this.pagearr.pagetotal){
        this.pagearr.page++
        this.param['page']++
      }
      
      this.getnewArticle()
    },
    goDetail(val){
      this.id=val.id
      this.getdata()
      // this.$router.push({ path: '/strategyDetail' ,query:{id:val.id}})
    },
    backFn(){
         this.$router.go(-1)
    },
    toserach(){
      this.param["keyword"] = this.keyword;
      this.getnewArticle()
    },
    replaceWhite(html) { // 处理富文本默认图片，视频大小
			let newContent = html.replace(/<video[^>]*>/gi, function(match, capture) {
				match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
				match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
				match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
				return match;
			}).replace(/<img[^>]*>/gi, function(match, capture) {
				match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
				match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
				match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
				return match;
			});
			newContent = newContent.replace(/style="[^"]+"/gi, function(match, capture) {
				match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi,
					'max-width:100%;');
				return match;
			});
			newContent = newContent.replace(/<br[^>]*\/>/gi, '');
			newContent = newContent.replace(/\<video/gi,
				'<video style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"').replace(/\<img/gi,
				'<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
			return newContent;
		},

  },
};
</script>
<style scoped>
.line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
video{
  width:100%;height:auto;
}
a{
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
.height-40{
  height: 40px;
}
</style>
<style>
video{
  width:100%;height:100%;
}
img{
  
    max-width: 600px;
}

</style>
